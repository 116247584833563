"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _ = require('..');
var _icons = require('../../icons');
var _reactintl = require('react-intl');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _theme = require('../../theme');
var _isUndefined = require('lodash/isUndefined'); var _isUndefined2 = _interopRequireDefault(_isUndefined);
var _constants = require('../../theme/constants');
const StyledKeyboardArrowDown = _styledcomponents2.default.call(void 0, _icons.KeyboardArrowDownIcon)`
  color: ${(_ref) => {
  let {
    theme: {
      palette
    }
  } = _ref;
  return `${_theme.colors[palette.mode].typography.typo2} !important;`;
}};
`;
function Select(_ref2) {
  let {
    id,
    placeholder,
    placeholderProps,
    RenderItem,
    RenderSelectedValue,
    options,
    onChange,
    selectedItem,
    disabledSearch = false,
    searchFunction,
    emptyOption,
    onSearchChange,
    SkeletonItem,
    isLoading,
    limitHeight = false,
    variant,
    size = "small",
    Header
  } = _ref2;
  const [search, setSearch] = _react.useState.call(void 0, "");
  const searchRef = _react.useRef.call(void 0, );
  const [open, setOpen] = _react.useState.call(void 0, false);
  const intl = _reactintl.useIntl.call(void 0, );
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  const renderedItems = _react.useMemo.call(void 0, () => !disabledSearch && searchFunction ? options.filter((option) => searchFunction(option, search)) : options, [search, disabledSearch, searchFunction, options]);
  const handleChangeNetwork = _react.useCallback.call(void 0, (evt) => {
    const selectedOption = options.find((option) => option.key === evt.target.value);
    if (selectedOption) {
      setOpen(false);
      setSearch("");
      setTimeout(() => onChange(selectedOption), 0);
    }
  }, [onChange, options]);
  const handleOnClose = _react.useCallback.call(void 0, () => {
    setOpen(false);
    setSearch("");
  }, []);
  const onRenderValue = (value) => {
    const optionFound = options.find((option) => option.key === value);
    if (value === "" || _isUndefined2.default.call(void 0, value) || !optionFound) {
      return /* @__PURE__ */ _react2.default.createElement(_.Typography, _extends({
        variant: "bodyBold",
        color: _theme.colors[mode].typography.typo5
      }, placeholderProps), placeholder);
    } else {
      return RenderSelectedValue ? /* @__PURE__ */ _react2.default.createElement(RenderSelectedValue, {
        item: optionFound,
        key: value
      }) : /* @__PURE__ */ _react2.default.createElement(RenderItem, {
        item: optionFound,
        key: value
      });
    }
  };
  const onSearch = (searchTerm) => {
    setSearch(searchTerm);
    if (onSearchChange) {
      onSearchChange(searchTerm);
    }
  };
  const handleOnOpen = () => {
    setOpen(true);
  };
  return /* @__PURE__ */ _react2.default.createElement(_.MuiSelect, {
    id,
    fullWidth: true,
    open,
    value: _optionalChain([selectedItem, 'optionalAccess', _3 => _3.key]) || "",
    onChange: handleChangeNetwork,
    onOpen: handleOnOpen,
    onClose: handleOnClose,
    placeholder,
    IconComponent: StyledKeyboardArrowDown,
    renderValue: onRenderValue,
    displayEmpty: true,
    variant,
    size,
    SelectDisplayProps: {
      style: {
        display: "flex",
        alignItems: "center",
        gap: "5px"
      }
    },
    MenuProps: {
      autoFocus: false,
      transformOrigin: {
        horizontal: "center",
        vertical: "top"
      },
      className: "MuiSelect-MuiMenu",
      ...!limitHeight ? {} : {
        slotProps: {
          paper: {
            sx: {
              maxHeight: "400px"
            }
          }
        }
      }
    }
  }, !disabledSearch && /* @__PURE__ */ _react2.default.createElement(_.ListSubheader, {
    disableGutters: true,
    sx: {
      marginBottom: _constants.SPACING.call(void 0, 2.5)
    }
  }, /* @__PURE__ */ _react2.default.createElement(_.TextField, {
    size: "small",
    autoFocus: true,
    placeholder: intl.formatMessage(_reactintl.defineMessage.call(void 0, {
      id: "o4fqRA",
      defaultMessage: [{
        "type": 0,
        "value": "Type to search..."
      }]
    })),
    fullWidth: true,
    value: search,
    inputRef: searchRef,
    InputProps: {
      startAdornment: /* @__PURE__ */ _react2.default.createElement(_.InputAdornment, {
        position: "start"
      }, /* @__PURE__ */ _react2.default.createElement(_icons.SearchIcon, null))
    },
    onChange: (e) => onSearch(e.target.value),
    onKeyDown: (e) => {
      if (e.key !== "Escape") {
        e.stopPropagation();
      }
    }
  })), Header && /* @__PURE__ */ _react2.default.createElement(_.ListSubheader, {
    disableGutters: true,
    sx: {
      background: _theme.colors[mode].background.emphasis,
      padding: _constants.SPACING.call(void 0, 3),
      marginBottom: _constants.SPACING.call(void 0, 2),
      borderRadius: _constants.SPACING.call(void 0, 2)
    }
  }, /* @__PURE__ */ _react2.default.createElement(Header.component, {
    props: Header.props
  })), renderedItems.length === 0 && (!isLoading || !SkeletonItem) && /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    alignItems: "center",
    justifyContent: "center"
  }, emptyOption), renderedItems.length === 0 && isLoading && SkeletonItem && /* @__PURE__ */ _react2.default.createElement(_.MenuItem, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: "5px"
    }
  }, /* @__PURE__ */ _react2.default.createElement(SkeletonItem, null)), renderedItems.map((option) => /* @__PURE__ */ _react2.default.createElement(_.MenuItem, {
    key: option.key,
    sx: {
      display: "flex",
      alignItems: "center",
      gap: "5px"
    },
    value: option.key
  }, /* @__PURE__ */ _react2.default.createElement(RenderItem, {
    item: option,
    key: option.key
  }))));
}


exports.Select = Select;
