"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _dialog = require('../dialog');
var _dialogtitle = require('../dialogtitle');
var _dialogcontent = require('../dialogcontent');
var _divider = require('../divider');
var _link = require('../link');
var _icons = require('../../icons');
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _common = require('../../common');
var _iconbutton = require('../iconbutton');
var _mui = require('tss-react/mui');
var _commontypes = require('common-types');
var _typography = require('../typography');
var _material = require('@mui/material');
var _reactintl = require('react-intl');
var _balmylogosmalllight = require('../../assets/balmy-logo-small-light'); var _balmylogosmalllight2 = _interopRequireDefault(_balmylogosmalllight);
var _theme = require('../../theme');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _transactiontypesmap = require('./transaction-types-map');
var _luxon = require('luxon');
var _viem = require('viem');
var _containerbox = require('../container-box');
var _currency = require('../../common/utils/currency');
var _isUndefined = require('lodash/isUndefined'); var _isUndefined2 = _interopRequireDefault(_isUndefined);
const StyledDialog = _mui.withStyles.call(void 0, _dialog.Dialog, (_ref) => {
  let {
    palette: {
      mode
    }
  } = _ref;
  return _common.createStyles.call(void 0, {
    paper: {
      border: `2px solid ${_theme.colors[mode].violet.violet500}`,
      borderBottom: "0",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
      overflow: "visible !important",
      "&:after": {
        borderLeft: `2px solid ${_theme.colors[mode].violet.violet500}`,
        borderRight: `2px solid ${_theme.colors[mode].violet.violet500}`,
        borderBottom: 0,
        backgroundImage: `url('data:image/svg+xml;utf8, <svg viewBox="0 0 200 110" xmlns="http://www.w3.org/2000/svg"><path d="M -15 110 L100 10 L215 110" fill="${_theme.colors[mode].background.tertiary.replace("#", "%23")}" stroke="${_theme.colors[mode].violet.violet500.replace("#", "%23")}" stroke-width="2" vector-effect="non-scaling-stroke"/></svg>')`,
        backgroundSize: "5% 13px",
        height: "10px",
        transform: "rotate(180deg)",
        backgroundPosition: "center",
        content: '" "',
        display: "block",
        position: "absolute",
        bottom: "-10px",
        left: "-2px",
        width: "calc(100% + 4px)"
      }
    }
  });
});
const StyledDialogTitle = _mui.withStyles.call(void 0, _dialogtitle.DialogTitle, (_ref2) => {
  let {
    palette: {
      mode
    },
    spacing
  } = _ref2;
  return _common.createStyles.call(void 0, {
    root: {
      padding: `${spacing(8)}`,
      gap: spacing(2),
      display: "flex",
      alignItems: "flex-end",
      backgroundColor: _theme.colors[mode].violet.violet500,
      borderTopLeftRadius: spacing(3),
      borderTopRightRadius: spacing(3),
      position: "relative",
      overflow: "hidden",
      "&:after": {
        content: '" "',
        position: "absolute",
        width: "70%",
        paddingBottom: "70%",
        left: "50%",
        transform: "translateX(-50%)",
        borderRadius: "50%",
        filter: "blur(46px)",
        background: _theme.colors[mode].violet.violet400,
        bottom: "-275%"
      }
    }
  });
});
const StyledDialogContent = _mui.withStyles.call(void 0, _dialogcontent.DialogContent, (_ref3) => {
  let {
    space
  } = _ref3;
  return _common.createStyles.call(void 0, {
    root: {
      padding: `${space.s05} ${space.s05} ${space.s07} !important`,
      gap: space.s05,
      display: "flex",
      flexDirection: "column"
    }
  });
});
const StyledBodySmallBold = _styledcomponents2.default.call(void 0, _typography.Typography).attrs((_ref4) => {
  let {
    theme: {
      palette: {
        mode
      }
    },
    ...rest
  } = _ref4;
  return {
    variant: "bodySmallBold",
    color: _theme.colors[mode].typography.typo2,
    ...rest
  };
})``;
const StyledSectionContent = _styledcomponents2.default.div`
  ${(_ref5) => {
  let {
    theme: {
      spacing
    }
  } = _ref5;
  return `
    gap: ${spacing(1)};
  `;
}}
  display: flex;
  flex-direction: column;
`;
const StyledDoubleSectionContent = _styledcomponents2.default.div`
  display: flex;
  flex-direction: row;
  ${StyledSectionContent} {
    flex: 1;
  }
`;
const StyledPositionId = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox).attrs({
  gap: 2.25,
  alignSelf: "start",
  alignItems: "center"
})`
  ${(_ref6) => {
  let {
    theme: {
      palette,
      spacing
    },
    $allowClick
  } = _ref6;
  return `
  padding: ${spacing(1)} ${spacing(2)};
  border: 1px solid ${_theme.colors[palette.mode].border.border2};
  border-radius: ${spacing(2)};
  transition: box-shadow 0.3s;
  ${$allowClick && `cursor: pointer;
    &:hover {
      box-shadow: ${_theme.colors[palette.mode].dropShadow.dropShadow100};
    }`}
`;
}}
`;
const ERC20ApprovalTransactionReceipt = (_ref7) => {
  let {
    transaction
  } = _ref7;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "WfQzX+",
    defaultMessage: [{
      "type": 0,
      "value": "Amount approved"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "bodySmallBold",
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.token.icon, transaction.data.amount.amount === _viem.maxUint256 && transaction.type === _commontypes.TransactionEventTypes.ERC20_APPROVAL ? /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "keaSAf",
    defaultMessage: [{
      "type": 0,
      "value": "Unlimited"
    }]
  }) : _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.amount.amount,
    token: transaction.data.token,
    intl
  }), " ", transaction.data.amount.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    amount: transaction.data.amount.amountInUSD,
    intl
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Vq7Qy2",
    defaultMessage: [{
      "type": 0,
      "value": "From address"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.owner)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "37Ou2z",
    defaultMessage: [{
      "type": 0,
      "value": "Spender"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.spender)));
};
const ERC20TransferTransactionReceipt = (_ref8) => {
  let {
    transaction
  } = _ref8;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "46X2UV",
    defaultMessage: [{
      "type": 0,
      "value": "Amount sent"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.token.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.amount.amount,
    token: transaction.data.token,
    intl
  }), " ", transaction.data.amount.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    amount: transaction.data.amount.amountInUSD,
    intl
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Vq7Qy2",
    defaultMessage: [{
      "type": 0,
      "value": "From address"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.from)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "IKS8dl",
    defaultMessage: [{
      "type": 0,
      "value": "To address"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.to)));
};
const NativeTransferTransactionReceipt = (_ref9) => {
  let {
    transaction
  } = _ref9;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "46X2UV",
    defaultMessage: [{
      "type": 0,
      "value": "Amount sent"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.tx.network.nativeCurrency.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.amount.amount,
    token: transaction.tx.network.nativeCurrency,
    intl
  }), " ", transaction.data.amount.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.amount.amountInUSD
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Vq7Qy2",
    defaultMessage: [{
      "type": 0,
      "value": "From address"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.from)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "IKS8dl",
    defaultMessage: [{
      "type": 0,
      "value": "To address"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.to)));
};
const DCAWithdrawTransactionReceipt = (_ref10) => {
  let {
    transaction
  } = _ref10;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "2Cpizn",
    defaultMessage: [{
      "type": 0,
      "value": "Withdrawn"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.toToken.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.withdrawn.amount,
    token: transaction.data.toToken,
    intl
  }), " ", transaction.data.withdrawn.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.withdrawn.amountInUSD
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "eocSIZ",
    defaultMessage: [{
      "type": 0,
      "value": "Withdrawn by"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.from)));
};
const SwapTransactionReceipt = (_ref11) => {
  let {
    transaction
  } = _ref11;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "iiypYP",
    defaultMessage: [{
      "type": 0,
      "value": "Sold token"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.tokenIn.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.amountIn.amount,
    token: transaction.data.tokenIn,
    intl
  }), " ", transaction.data.amountIn.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.amountIn.amountInUSD
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Boc4u1",
    defaultMessage: [{
      "type": 0,
      "value": "Bought token"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.tokenOut.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.amountOut.amount,
    token: transaction.data.tokenOut,
    intl
  }), " ", transaction.data.amountOut.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.amountOut.amountInUSD
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "hYB4+7",
    defaultMessage: [{
      "type": 0,
      "value": "Swapped by"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.from)), transaction.data.recipient && /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "8bvWAd",
    defaultMessage: [{
      "type": 0,
      "value": "Transfered to"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.recipient)));
};
const DCATerminatedTransactionReceipt = (_ref12) => {
  let {
    transaction
  } = _ref12;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "2nwXLN",
    defaultMessage: [{
      "type": 0,
      "value": "Withdrawn swapped"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.toToken.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.withdrawnSwapped.amount,
    token: transaction.data.toToken,
    intl
  }), " ", transaction.data.withdrawnSwapped.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.withdrawnSwapped.amountInUSD
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "9uosIQ",
    defaultMessage: [{
      "type": 0,
      "value": "Withdrawn funds"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.fromToken.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.withdrawnRemaining.amount,
    token: transaction.data.fromToken,
    intl
  }), " ", transaction.data.withdrawnRemaining.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.withdrawnRemaining.amountInUSD
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "eocSIZ",
    defaultMessage: [{
      "type": 0,
      "value": "Withdrawn by"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.from)));
};
const DCAModifyTransactionReceipt = (_ref13) => {
  let {
    transaction
  } = _ref13;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  const {
    from,
    fromToken,
    fromIsYield,
    rate,
    oldRate,
    remainingSwaps,
    oldRemainingSwaps,
    remainingLiquidity,
    oldRemainingLiquidity
  } = transaction.data;
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "j3xAJf",
    defaultMessage: [{
      "type": 0,
      "value": "Total Invested"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 0.5,
    alignItems: "center"
  }, /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, fromToken.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: oldRemainingLiquidity.amount,
    token: fromToken,
    sigFigs: 2,
    intl
  }), !_isUndefined2.default.call(void 0, oldRemainingLiquidity.amountInUSD) && ` ($${oldRemainingLiquidity.amountInUSD})`), /* @__PURE__ */ _react2.default.createElement(_icons.ArrowRightIcon, null), oldRemainingLiquidity.amount === remainingLiquidity.amount ? /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, "=") : /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    color: "success.dark"
  }, _currency.formatCurrencyAmount.call(void 0, {
    amount: remainingLiquidity.amount,
    token: fromToken,
    sigFigs: 2,
    intl
  }), !_isUndefined2.default.call(void 0, remainingLiquidity.amountInUSD) && ` ($${remainingLiquidity.amountInUSD})`))), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "5Xhtrd",
    defaultMessage: [{
      "type": 0,
      "value": "Rate"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 0.5,
    alignItems: "center"
  }, /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, fromToken.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: oldRate.amount,
    token: fromToken,
    sigFigs: 2,
    intl
  }), !_isUndefined2.default.call(void 0, oldRate.amountInUSD) && ` ($${oldRate.amountInUSD})`), fromIsYield && Number(oldRate.amount) > 0 && /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "yhA8mK",
    defaultMessage: [{
      "type": 0,
      "value": "+ yield"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(_icons.ArrowRightIcon, null), oldRate.amount === rate.amount ? /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, "=") : /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    color: "success.dark"
  }, _currency.formatCurrencyAmount.call(void 0, {
    amount: rate.amount,
    token: fromToken,
    sigFigs: 2,
    intl
  }), !_isUndefined2.default.call(void 0, rate.amountInUSD) && ` ($${rate.amountInUSD})`), fromIsYield && Number(rate.amount) > 0 && /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    color: "success.dark"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "yhA8mK",
    defaultMessage: [{
      "type": 0,
      "value": "+ yield"
    }]
  }))))), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Sfa2Rq",
    defaultMessage: [{
      "type": 0,
      "value": "Swaps left"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 0.5,
    alignItems: "center"
  }, /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, oldRemainingSwaps === 1 ? /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "wQm/qb",
    defaultMessage: [{
      "type": 0,
      "value": "1 swap"
    }]
  }) : /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "vmKy2D",
    defaultMessage: [{
      "type": 1,
      "value": "swaps"
    }, {
      "type": 0,
      "value": " swaps"
    }],
    values: {
      swaps: Number(oldRemainingSwaps)
    }
  })), /* @__PURE__ */ _react2.default.createElement(_icons.ArrowRightIcon, null), remainingSwaps === oldRemainingSwaps ? /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, "=") : /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    color: "success.dark"
  }, oldRemainingSwaps === 1 ? /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "wQm/qb",
    defaultMessage: [{
      "type": 0,
      "value": "1 swap"
    }]
  }) : /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "L9rClv",
    defaultMessage: [{
      "type": 1,
      "value": "swaps"
    }, {
      "type": 0,
      "value": " swap"
    }],
    values: {
      swaps: Number(remainingSwaps)
    }
  })))), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "ygGjH9",
    defaultMessage: [{
      "type": 0,
      "value": "Modified by"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, from)));
};
const DCACreateTransactionReceipt = (_ref14) => {
  let {
    transaction
  } = _ref14;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "lB+wQP",
    defaultMessage: [{
      "type": 0,
      "value": "Rate"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.fromToken.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.rate.amount,
    token: transaction.data.fromToken,
    intl
  }), " ", transaction.data.rate.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.rate.amountInUSD
  })})`)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "395ORK",
    defaultMessage: [{
      "type": 0,
      "value": "Created by"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.from)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "DE8/1l",
    defaultMessage: [{
      "type": 0,
      "value": "Owned by"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.owner)));
};
const EarnDepositTransactionReceipt = (_ref15) => {
  let {
    transaction
  } = _ref15;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "BUFX9/",
    defaultMessage: [{
      "type": 0,
      "value": "Deposited"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.asset.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.assetAmount.amount,
    token: transaction.data.asset,
    intl
  }), " ", transaction.data.assetAmount.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.assetAmount.amountInUSD
  })})`)));
};
const EarnIncreaseTransactionReceipt = (_ref16) => {
  let {
    transaction
  } = _ref16;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "nO3/Tn",
    defaultMessage: [{
      "type": 0,
      "value": "Deposited"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.data.asset.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.data.assetAmount.amount,
    token: transaction.data.asset,
    intl
  }), " ", transaction.data.assetAmount.amountInUSD && `($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: transaction.data.assetAmount.amountInUSD
  })})`)));
};
const EarnWithdrawTransactionReceipt = (_ref17) => {
  let {
    transaction
  } = _ref17;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "TUilGC",
    defaultMessage: [{
      "type": 0,
      "value": "Withdrew"
    }]
  })), transaction.data.withdrawn.map((withdrawn) => /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    },
    key: withdrawn.token.address
  }, withdrawn.token.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: withdrawn.amount.amount,
    token: withdrawn.token,
    intl
  }), " ", withdrawn.token.symbol, withdrawn.amount.amountInUSD ? ` ($${_currency.formatUsdAmount.call(void 0, {
    intl,
    amount: withdrawn.amount.amountInUSD
  })})` : "")));
};
const DCAPermissionsModifiedTransactionReceipt = (_ref18) => {
  let {
    transaction
  } = _ref18;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "RuzTlw",
    defaultMessage: [{
      "type": 0,
      "value": "New permissions set:"
    }]
  })), transaction.data.permissions.map((_ref19, index) => {
    let {
      permissions,
      label
    } = _ref19;
    return /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
      key: index,
      sx: {
        display: "flex",
        alignItems: "center",
        gap: spacing(2)
      }
    }, label, ":", permissions.map((permission) => ` ${permission}`));
  }));
};
const DcaTransferTransactionReceipt = (_ref20) => {
  let {
    transaction
  } = _ref20;
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Vq7Qy2",
    defaultMessage: [{
      "type": 0,
      "value": "From address"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.from)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "IKS8dl",
    defaultMessage: [{
      "type": 0,
      "value": "To address"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, transaction.data.to)));
};
const buildDcaTransactionReceiptForEvent = (dcaTransaction, onClickPositionId) => {
  let dcaReceipt;
  switch (dcaTransaction.type) {
    case _commontypes.TransactionEventTypes.DCA_WITHDRAW:
      dcaReceipt = /* @__PURE__ */ _react2.default.createElement(DCAWithdrawTransactionReceipt, {
        transaction: dcaTransaction
      });
      break;
    case _commontypes.TransactionEventTypes.DCA_MODIFIED:
      dcaReceipt = /* @__PURE__ */ _react2.default.createElement(DCAModifyTransactionReceipt, {
        transaction: dcaTransaction
      });
      break;
    case _commontypes.TransactionEventTypes.DCA_CREATED:
      dcaReceipt = /* @__PURE__ */ _react2.default.createElement(DCACreateTransactionReceipt, {
        transaction: dcaTransaction
      });
      break;
    case _commontypes.TransactionEventTypes.DCA_PERMISSIONS_MODIFIED:
      dcaReceipt = /* @__PURE__ */ _react2.default.createElement(DCAPermissionsModifiedTransactionReceipt, {
        transaction: dcaTransaction
      });
      break;
    case _commontypes.TransactionEventTypes.DCA_TRANSFER:
      dcaReceipt = /* @__PURE__ */ _react2.default.createElement(DcaTransferTransactionReceipt, {
        transaction: dcaTransaction
      });
      break;
    case _commontypes.TransactionEventTypes.DCA_TERMINATED:
      dcaReceipt = /* @__PURE__ */ _react2.default.createElement(DCATerminatedTransactionReceipt, {
        transaction: dcaTransaction
      });
      break;
  }
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, dcaReceipt, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "uS51Qo",
    defaultMessage: [{
      "type": 0,
      "value": "Position"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledPositionId, {
    onClick: () => onClickPositionId && onClickPositionId({
      positionId: dcaTransaction.data.positionId,
      chainId: dcaTransaction.tx.chainId,
      hub: dcaTransaction.data.hub
    }),
    $allowClick: !!onClickPositionId
  }, /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 0.5,
    alignItems: "center"
  }, dcaTransaction.data.fromToken.icon, /* @__PURE__ */ _react2.default.createElement(_icons.ArrowRightIcon, null), dcaTransaction.data.toToken.icon), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, "#", dcaTransaction.data.positionId))));
};
const buildTransactionReceiptForEvent = (transaction, onClickPositionId) => {
  switch (transaction.type) {
    case _commontypes.TransactionEventTypes.ERC20_APPROVAL:
      return /* @__PURE__ */ _react2.default.createElement(ERC20ApprovalTransactionReceipt, {
        transaction
      });
    case _commontypes.TransactionEventTypes.ERC20_TRANSFER:
      return /* @__PURE__ */ _react2.default.createElement(ERC20TransferTransactionReceipt, {
        transaction
      });
    case _commontypes.TransactionEventTypes.SWAP:
      return /* @__PURE__ */ _react2.default.createElement(SwapTransactionReceipt, {
        transaction
      });
    case _commontypes.TransactionEventTypes.EARN_CREATED:
      return /* @__PURE__ */ _react2.default.createElement(EarnDepositTransactionReceipt, {
        transaction
      });
    case _commontypes.TransactionEventTypes.EARN_INCREASE:
      return /* @__PURE__ */ _react2.default.createElement(EarnIncreaseTransactionReceipt, {
        transaction
      });
    case _commontypes.TransactionEventTypes.NATIVE_TRANSFER:
      return /* @__PURE__ */ _react2.default.createElement(NativeTransferTransactionReceipt, {
        transaction
      });
    case _commontypes.TransactionEventTypes.EARN_WITHDRAW:
      return /* @__PURE__ */ _react2.default.createElement(EarnWithdrawTransactionReceipt, {
        transaction
      });
    case _commontypes.TransactionEventTypes.DCA_WITHDRAW:
    case _commontypes.TransactionEventTypes.DCA_MODIFIED:
    case _commontypes.TransactionEventTypes.DCA_CREATED:
    case _commontypes.TransactionEventTypes.DCA_PERMISSIONS_MODIFIED:
    case _commontypes.TransactionEventTypes.DCA_TRANSFER:
    case _commontypes.TransactionEventTypes.DCA_TERMINATED:
      return buildDcaTransactionReceiptForEvent(transaction, onClickPositionId);
  }
  return null;
};
const TransactionReceipt = (_ref21) => {
  let {
    transaction,
    open,
    onClose,
    onClickPositionId
  } = _ref21;
  const {
    spacing
  } = _material.useTheme.call(void 0, );
  const intl = _reactintl.useIntl.call(void 0, );
  if (!transaction) {
    return null;
  }
  return /* @__PURE__ */ _react2.default.createElement(StyledDialog, {
    open,
    scroll: "paper",
    maxWidth: "xs",
    fullWidth: true,
    PaperProps: {
      id: "paper-id"
    },
    onClose
  }, /* @__PURE__ */ _react2.default.createElement(StyledDialogTitle, null, /* @__PURE__ */ _react2.default.createElement(_balmylogosmalllight2.default, {
    size: spacing(10)
  }), /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "h3Bold",
    color: _theme.baseColors.violet.violet100
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "LWE8HG",
    defaultMessage: [{
      "type": 0,
      "value": "Receipt"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(_iconbutton.IconButton, {
    "aria-label": "close",
    size: "small",
    onClick: onClose,
    style: {
      position: "absolute",
      top: 0,
      right: 0,
      padding: spacing(4)
    }
  }, /* @__PURE__ */ _react2.default.createElement(_icons.CloseIcon, {
    fontSize: "inherit",
    sx: {
      color: _theme.baseColors.violet.violet100
    }
  }))), /* @__PURE__ */ _react2.default.createElement(StyledDialogContent, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "AGGTba",
    defaultMessage: [{
      "type": 0,
      "value": "Transaction Type"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, intl.formatMessage(_transactiontypesmap.TRANSACTION_TYPE_TITLE_MAP[transaction.type]))), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "afQ81a",
    defaultMessage: [{
      "type": 0,
      "value": "Date & Time"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, null, _luxon.DateTime.fromSeconds(Number(transaction.tx.timestamp)).toLocaleString({
    ..._luxon.DateTime.DATETIME_FULL,
    timeZoneName: void 0
  }))), buildTransactionReceiptForEvent(transaction, onClickPositionId), /* @__PURE__ */ _react2.default.createElement(StyledDoubleSectionContent, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Hxykt5",
    defaultMessage: [{
      "type": 0,
      "value": "Network"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.tx.network.mainCurrency.icon, transaction.tx.network.name)), /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "GZpeDX",
    defaultMessage: [{
      "type": 0,
      "value": "Network Fee"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(StyledBodySmallBold, {
    sx: {
      display: "flex",
      alignItems: "center",
      gap: spacing(2)
    }
  }, transaction.tx.network.nativeCurrency.icon, _currency.formatCurrencyAmount.call(void 0, {
    amount: transaction.tx.spentInGas.amount,
    token: transaction.tx.network.nativeCurrency,
    intl
  }), " ", transaction.tx.network.nativeCurrency.symbol))), /* @__PURE__ */ _react2.default.createElement(_divider.DividerBorder2, null), /* @__PURE__ */ _react2.default.createElement(StyledDoubleSectionContent, null, /* @__PURE__ */ _react2.default.createElement(StyledSectionContent, null, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "labelRegular"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "PuyqSh",
    defaultMessage: [{
      "type": 0,
      "value": "Transaction ID"
    }]
  })), /* @__PURE__ */ _react2.default.createElement(_link.Link, {
    variant: "bodySmallBold",
    href: transaction.tx.explorerLink,
    target: "_blank"
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "O+yqTs",
    defaultMessage: [{
      "type": 0,
      "value": "View in explorer"
    }]
  }))))));
};



exports.TRANSACTION_TYPE_TITLE_MAP = _transactiontypesmap.TRANSACTION_TYPE_TITLE_MAP; exports.TransactionReceipt = TransactionReceipt;
